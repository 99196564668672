require(
	// Define module dependencies
	[
		"backbone",
		"shared/GlobalScript",
		"utils",
	],

	function( Backbone, GlobalScript, Utils ) {
		"use strict";

		/**
		 * @class SiteErrorView
		 * @extends Backbone.View
		 */
		var App = Backbone.View.extend( {
			el : "body", // Bind to the body element for this broad catch-all view

			initialize : function() {
				// Set up view-level variables
				this.setVariables();
			},

			// Begin setter functions
			setVariables : function() {
				this.globalScript = new GlobalScript( { shouldFix : false } );
			},
			// End setter functions
		} );

		// Wait for document ready and then bootstrap the app
		Utils.loadApp( App );
	}
);
